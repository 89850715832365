.header {
  position: fixed;
  z-index: 100;
  // padding: 1rem;
  .main-container {
    box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
    background-color: rgba(255, 255, 255, 0.477);
    width: 100%;
    margin: auto;
    --bs-navbar-padding-y: 0;
    .container-fluid {
      align-items: stretch;
      button {
        color: white !important;
        padding: 0;
        margin: 0;
        border: 0;
        i {
          color: black;
        }
      }
      .iconsAndNumber {
        padding-right: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        .icons-outer {
          .icons-inner {
            display: flex;
            align-items: center;
            flex-direction: row;
            height: 20px;
            a {
              width: 100%;
              text-decoration: none !important;
              color: black;
              margin-bottom: 0;
              font-weight: 500;
              i {
                color: #351f99;
                padding-right: 6px;
              }
            }
          }
        }
        .icons-number {
          i {
            color: #351f99;
            padding-right: 6px;
          }
          a {
            text-decoration: none !important;
            color: black;
            margin-bottom: 0;
            font-weight: 500;
            display: flex;
          }
        }
      }
    }

    .row {
      height: 100%;
      display: flex;
      align-items: stretch;
      .navbar-brand {
        background-color: white;
      }

      .logo {
        height: 70%;
        width: 70%;
        align-items: center;
        display: flex;

        a {
          align-items: center;
          justify-content: center;
          width: 100%;
          display: flex;

          img {
            max-width: 80%;
          }
        }
      }
      .menu-bar {
        height: 100%;
        justify-content: end;
        .navbar-nav {
          justify-content: space-between;
          width: 100%;
          max-height: var(--bs-scroll-height, 100vh);
          // overflow-y: auto;
          .nav-item {
            align-items: center;
            justify-content: center;
            display: flex;
            border-bottom: 2px solid transparent;
            padding: 19px;
            .dropdown-menu {
              cursor: pointer;
              overflow-y: auto;
              padding: 0;
              margin: 0;
              list-style: none;
              // position: absolute;
              z-index: 1000;
              width: fit-content;
              background-color: white;
              border: 1px solid #ccc;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
              /* Hide scrollbar for Chrome, Safari and Opera */
              ::-webkit-scrollbar {
                width: 0px;
              }
              /* Hide scrollbar for IE, Edge and Firefox */
              // scrollbar-width: none; /* For Firefox */
              // -ms-overflow-style: none; /* For Internet Explorer and Edge */
              .dropdown-list {
                padding: 2px 5px;
                border-bottom: 1px solid #f5f5f5;
                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }

            &:hover {
              border-bottom: 2px solid orange;
              background-color: white;
              .nav-link {
                color: orange !important;
                a {
                  color: white;
                }
              }
            }
            &:active {
              border-bottom: 2px solid orange;
              background-color: white;
              .nav-link {
                color: orange !important;
                a {
                  color: white;
                }
              }
            }
            .nav-link {
              color: black;
              font-weight: 500;
              position: relative;
            }

            .dropdown-nav {
              display: none;
              height: fit-content;
              position: absolute;
              width: fit-content;
              top: 100%;
              left: 0px;
              background-color: white;
              border-radius: 10px;
              list-style: none;
              margin: 0;
              .dropdown-list {
                cursor: pointer;
                .dropdown-item {
                  display: block;
                  width: 100%;
                  /* padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x); */
                  clear: both;

                  font-weight: 400;
                  color: var(--bs-dropdown-link-color);
                  text-align: inherit;
                  text-decoration: none;
                  white-space: nowrap;
                  background-color: transparent;
                  border: 0;
                  border-radius: var(--bs-dropdown-item-border-radius, 0);
                  padding: 1px;
                  // border-radius: var(--bs-dropdown-item-border-radius, 0);
                }
              }
            }
            &:hover {
              .dropdown-nav {
                display: block;
              }
            }
          }
          .iconsnumbers {
            .dropdown-menu {
            }
            &:hover {
              border-bottom: 2px solid white;
              background-color: white;
              .nav-link {
                color: white !important;
              }
            }
          }
        }
      }
      button {
        width: 100px;
        color: white;
      }
    }
    hr {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .header {
    width: 100%;
    .main-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .container-fluid {
        padding: 0;
        button {
          border: none;
          width: 100px;
          color: white !important;
          i {
            font-size: 30px;

            color: black;
          }
        }
        .menu-bar {
          background-color: white;
          a {
            color: black !important;
          }
        }
      }
      hr {
        display: block;
        margin-top: 0;
        margin: 0;
        margin: auto;
        width: 90%;
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .main-container {
      .container-fluid {
        display: flex;
        align-items: stretch;
      }
      .row {
        .logo {
          height: 100%;
          display: flex;
          align-items: center;
          // padding: 0;
          a {
            text-align: center;
            padding: 10px 0;
          }
        }
        .menu-bar {
          padding-right: 0 !important;
          justify-content: center;
          .navbar-nav {
            // padding-left: 2rem;
            width: 100%;
            .nav-item {
              align-items: start;
              justify-content: flex-start;
              display: flex;
              .dropdown-menu {
                width: 100%;
                .dropdown-list {
                  padding: 10px 5px;
                  border-bottom: 1px solid #f5f5f5;
                  &:hover {
                    background-color: #f5f5f5;
                  }
                }
              }
            }
          }
        }
      }
      .iconsAndNumber {
        flex-direction: column-reverse !important;

        .icons-outer {
        }
      }
      hr {
        display: block;
        margin-top: 0;
        margin: 0;
        margin: auto;
        width: 95%;
      }
    }
  }
}
