.uniform-category-main {
  width: 100%;
  background-color: white;
  .icon-bar {
    position: fixed;
    right: 0;
    top: 90%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-15%);
    i {
      display: flex;
      height: 30px;
      width: 30px;
    }
  }
  s .icon-bar a {
    display: block;
    text-align: center;
    padding: 12px;
    transition: all 0.3s ease;
    color: white;
    font-size: 30px;
    text-decoration: none;
  }

  .icon-bar a:hover {
    background-color: #128974;
  }

  .whatsapp {
    background: #25d366;
    border-radius: 50%;
  }
  .category-hero-img-section {
    // background-image: url("../../assets/homehero.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .category-main-hero-content-section {
      padding: 7%;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      .category-content {
        padding-top: 3rem;
        font-size: 4rem;
        font-weight: 700;
      }
    }
  }
  .logo-carousel {
    .owl-carousel {
      .owl-item {
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
      }
    }
    .owl-theme {
      width: 70%;
      margin: auto;
    }
  }

  .category-card-outer {
    width: 90%;
    margin: auto;
    padding-top: 2%;
    row-gap: 10px;

    .category-card-inner {
      padding: 3rem;
      --bs-gutter-x: 0;
      margin-left: 0 !important;
      background-color: #f0f0f0;
      border-radius: 12px;
      width: 100%;
      .category-card-section-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        .category-card-big {
          cursor: pointer;
          box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

          // margin-left: 0 !important;
          background-color: white;
          border-radius: 12px;
          width: 100%;
          height: 100%;
          padding: 2%;

          .card-image {
            padding: 0;
            // background-color: #f0f0f0;
            .card-image-outer {
              border-radius: 12px;
              position: relative;
              height: 100%;
              .card-color {
                position: absolute;
                top: 40%;
                left: 10px;
                .color-outer {
                  padding: 6px;
                  .color1 {
                    width: 12px;
                    height: 12px;
                    background-color: pink;
                    border-radius: 12px;
                  }
                }
              }
              .card-image-just-outer {
                max-width: 130%;
                background-color: #f0f0f0;
                width: 120%;
                height: 100%;
                display: flex;
                align-items: center;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  z-index: 2;
                  // padding-left: 2rem;
                }
              }
            }
          }
          .card-about {
            h4 {
              text-align: left;
              padding-top: 6%;
              padding-bottom: 6%;
              width: 80%;
              margin-left: auto;
            }
            p {
              text-align: left;
              position: relative;
              z-index: 5;
              width: 80%;
              margin-left: auto;
            }
            .submit-btn {
              padding-top: 0px !important;
              float: right;
              .view-more-btn {
                margin-top: 11px;
                z-index: 10;
                margin-left: auto;
                width: fit-content;
                position: relative;
                border: none;
                background-image: linear-gradient(
                  -79deg,
                  #351f99 0.36%,
                  #0099e5 117.12%
                );
                border-radius: 7px;
                padding: 9px;
                color: white;
                width: 7rem;
              }
            }
          }
        }
      }
      .category-card-section-2 {
        height: 100%;

        // padding-top: 0.5rem;
        // padding-bottom: 0.5rem;
        .category-card-section-2-outer {
          border-radius: 12px;
          // row-gap: 10px;
          margin-right: 0;
          margin-left: 0;
          height: 100%;
          .category-card-small-1 {
            width: 100%;
            height: 50%;
            padding-bottom: 5px;
            .category-card-small-1-inner {
              cursor: pointer;
              box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
              height: 100%;
              margin: 0;
              background-color: white;
              border-radius: 12px;
              padding: 2%;
              .small-image-1 {
                background-color: #f0f0f0;
                border-radius: 12px;
                height: 100%;
                position: relative;
                .card-color {
                  position: absolute;
                  top: 30%;
                  left: 20px;
                  .color-outer {
                    padding: 6px;
                    .color1 {
                      width: 12px;
                      height: 12px;
                      background-color: pink;
                      border-radius: 12px;
                    }
                  }
                }
                img {
                  margin-left: 1rem;
                  max-width: 100%;
                  max-height: 95%;
                }
              }
              .small-about-1 {
                .small-about-width-1 {
                  width: 75%;
                  margin-left: auto;
                  h4 {
                    text-align: left;
                    padding-top: 1%;
                    padding-bottom: 1%;
                  }
                  p {
                    text-align: left;
                    font-size: 14px;
                    margin: 0;
                  }
                  .submit-btn {
                    padding-top: 10px;
                    float: right;
                    .view-more-btn {
                      margin-top: 11px;
                      z-index: 10;
                      margin-left: auto;
                      width: fit-content;
                      position: relative;
                      border: none;
                      background-image: linear-gradient(
                        -79deg,
                        #351f99 0.36%,
                        #0099e5 117.12%
                      );
                      border-radius: 7px;
                      padding: 9px;
                      color: white;
                      width: 7rem;
                    }
                  }
                }
              }
            }
          }
          .category-card-small-2 {
            width: 100%;
            height: 50%;
            padding-top: 5px;
            .category-card-small-2-inner {
              cursor: pointer;
              box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
              margin: 0;
              height: 100%;
              background-color: white;
              border-radius: 12px;
              padding: 2%;
              .small-image-2 {
                background-color: #f0f0f0;
                border-radius: 12px;
                height: 100%;
                position: relative;
                .card-color {
                  position: absolute;
                  top: 30%;
                  left: 20px;
                  .color-outer {
                    padding: 6px;
                    .color1 {
                      width: 12px;
                      height: 12px;
                      background-color: pink;
                      border-radius: 12px;
                    }
                  }
                }
                img {
                  margin-left: 1rem;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              .small-about-2 {
                .small-about-width-2 {
                  width: 75%;
                  margin-left: auto;
                  h4 {
                    text-align: left;
                    padding-top: 1%;
                    padding-bottom: 1%;
                  }
                  p {
                    text-align: left;
                    font-size: 14px;
                    margin: 0;
                  }
                  .submit-btn {
                    padding-top: 10px;
                    float: right;
                    .view-more-btn {
                      margin-top: 11px;
                      z-index: 10;
                      margin-left: auto;
                      width: fit-content;
                      position: relative;
                      border: none;
                      background-image: linear-gradient(
                        -79deg,
                        #351f99 0.36%,
                        #0099e5 117.12%
                      );
                      border-radius: 7px;
                      padding: 9px;
                      color: white;
                      width: 7rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(odd) {
      .category-card-inner {
        flex-direction: row;
      }
    }
    &:nth-child(even) {
      .category-card-inner {
        flex-direction: row-reverse;
      }
    }
  }

  .last-section {
    padding: 80px;
    .signature-img {
      background-image: url("../../assets/category-img.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 95%;
      height: 30rem;
      margin: auto;
      border-radius: 50px;
      .signature-container {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50px;
        display: flex;
        align-items: center;
        height: 100%;
        .signature-content {
          color: white;
          font-weight: 700;
          font-size: 4rem;
          width: 30%;
          margin: auto;
          text-align: center;
        }
      }
    }
  }

  .subcategories {
    margin: 20px;

    .heading-categories {
      padding-top: 1rem;
      // padding-bottom:1rem;
      .sub-heading {
        width: 252px;
        margin: auto;
        font-size: 37px;
        font-family: Oswald;
        -webkit-text-fill-color: #0000;
        -webkit-background-clip: text;
        background-clip: text;
        text-align: center;
        background-image: -webkit-linear-gradient(
          45deg,
          #0f2699 19.79%,
          #00aaff 84.88%
        );
      }
    }

    .carousal-div {
      width: 90%;
      margin: auto;
      position: relative;
      .owl-nav {
        z-index: 0;
        position: absolute;
        width: 105%;
        left: 50%;
        top: 56%;
        transform: translatey(-150%) translateX(-50%);
        display: flex;
        justify-content: space-between;
        button {
          transform: scale(2.5);
          &:hover {
            background-color: transparent;
            color: black;
          }
        }
      }
      .owl-dots {
        display: none;
      }
      .owl-stage-outer {
        z-index: 2;
        .owl-stage {
          padding-bottom: 0px;
        }
      }
      .owl-item {
        padding: 2rem;
      }
      .owl-item:nth-child(3n) .category-card {
        box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
        background-image: url("../../assets/Ellipse\ 37.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: bottom;
        .title {
          color: green;
        }
      }

      .owl-item:nth-child(3n + 1) .category-card {
        box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
        background-image: url("../../assets/Ellipse\ 37\ \(1\).png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: bottom;
        .title {
          color: blue;
        }
      }
      .owl-item:nth-child(3n + 2) .category-card {
        box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
        background-image: url("../../assets/Ellipse\ 37\ \(2\).png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: bottom;
        .title {
          color: orange;
        }
      }

      .div {
        .category-card {
          padding: 10px;
          cursor: pointer;
          background-color: #ffffff;
          box-shadow: 5px 5px 30px 2px rgba(128, 128, 128, 0.1);

          // width: 97%;
          margin: auto;
          height: 175px;
          border-radius: 19px;
          display: flex;

          .title {
            cursor: pointer !important;
            font-family: serif;
            margin: auto;
            font-size: 30px;
            text-align: center;
            color: blue;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .uniform-category-main {
    .category-hero-img-section {
      .category-main-hero-content-section {
        padding-top: 14%;
        .category-content {
          text-align: center;
          font-size: 54px;
        }
      }
    }
    .uniform-category-main {
      .logo-carousel {
        .owl-carousel {
          .owl-item {
            padding: 10px;
          }
        }
      }
    }
    .category-card-outer {
      row-gap: 0;
      padding-top: 0;
      width: 90%;
      .category-card-inner {
        border-radius: 2px;
        background-color: white;
        padding: 0rem;
        // padding-left: 2rem;

        // padding-right: 2rem;
        .category-card-section-1 {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          .category-card-big {
            box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
            padding: 3%;
            .card-image {
              .card-image-outer {
                .card-image-just-outer {
                  width: 100%;
                  img {
                    padding: 10%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                }
                .card-color {
                  .color-outer {
                    .color1 {
                      width: 12px;
                      height: 12px;
                      background-color: pink;
                      border-radius: 12px;
                    }
                  }
                }
              }
            }
            .card-about {
              margin: 0;
              padding: 0;
              h4 {
                margin: 0;
                text-align: start;
                padding: 0;
                padding-top: 0.7rem;
                font-size: 18px;
              }
              p {
                width: 100%;
                text-align: justify;
                font-size: 12px;
              }
              .submit-btn {
                padding-top: 0 !important;
                float: left !important;
              }
            }
          }
        }
        .category-card-section-2 {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          .category-card-section-2-outer {
            --bs-gutter-x: 0;
            height: auto;
            row-gap: 1rem;
            .category-card-small-1 {
              .category-card-small-1-inner {
                box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

                height: auto;
                padding: 3%;
                .small-image-1 {
                  min-height: 225px;
                  display: flex;
                  justify-content: space-between;
                  .card-color {
                    .color-outer {
                      .color1 {
                        width: 12px;
                        height: 12px;
                        background-color: pink;
                        border-radius: 12px;
                      }
                    }
                  }
                  img {
                    padding: 10%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                }
              }
              .small-about-1 {
                padding: 0;
                .small-about-width-1 {
                  width: 100% !important;
                  h4 {
                    padding-bottom: 0;
                    font-size: 18px;
                    text-align: start !important;
                  }
                  p {
                    font-size: 12px !important;
                    text-align: justify !important;
                    margin-bottom: 1rem !important;
                  }
                  .submit-btn {
                    padding-top: 0 !important;
                    float: left !important;
                  }
                }
              }
            }
            .category-card-small-2 {
              .category-card-small-2-inner {
                box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);

                padding: 3%;
                height: fit-content;
                .small-image-2 {
                  display: flex;
                  justify-content: space-between;
                  .card-color {
                    .color-outer {
                      .color1 {
                        width: 12px;
                        height: 12px;
                        background-color: pink;
                        border-radius: 12px;
                      }
                    }
                  }
                  img {
                    padding: 10%;
                    max-width: 70%;
                    height: 100%;
                    z-index: 2;
                    margin: auto;
                  }
                }
              }
              .small-about-2 {
                padding: 0;
                .small-about-width-2 {
                  width: 100% !important;
                  h4 {
                    font-size: 18px;
                    text-align: start !important;
                    padding-bottom: 0;
                  }
                  p {
                    text-align: justify !important;
                    margin-bottom: 1rem !important;
                  }
                  .submit-btn {
                    padding-top: 0 !important;
                    float: left !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .last-section {
      width: 95%;
      margin: auto;
      padding: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .signature-img {
        height: 11rem;
        border-radius: 5px;
        .signature-container {
          border-radius: 5px;

          .signature-content {
            width: 80%;
            font-size: 2rem;
          }
        }
      }
    }
  }
}
